import React, { useContext, useState } from "react";
import Fade from 'react-reveal/Fade';
import { navigate } from 'gatsby'
import { InputWrapper, Wrapper } from "./styled"
import { Modal, ModalCentered, ModalWrapper, SuccessModalWrapper } from '../../modules/contact/Styled';
import { ErrorMessage, Field, Form, Formik } from "formik";
import { object, string } from "yup";
import { ContactContext } from '../../internal/context/ContactContext';

const Questionairs = () => {
  const { showModal, setShowModal, sendMessage, loading } = useContext(ContactContext);
  const [showSuccess, setShowSuccess] = useState(false);
  const [errors, setErrors] = useState({});

  const onSubmit = async (values) => {
    try {
      await sendMessage({ email: values.email, message: JSON.stringify(values) });
      setShowSuccess(true);
    } catch (e) {
      if (e.inner) {
        setErrors(e.inner.reduce((acc, { path, errors }) => {
          acc[path] = errors.join(', ');
          return acc;
        }, {}));
      }
    }
  }

  if (showSuccess) {
    return (
      <ModalCentered onClick={() => setShowSuccess(false)}>
        <Fade bottom duration={200}>
          <SuccessModalWrapper>
            <div>
              <div className='progress'>
                <span></span>
              </div>
              <h3 className="font-bold text-2xl my-8">We recieved your request</h3>
              <p className="mb-8">Thanks for contacting use, one of our representative will be with you soon.</p>
              <button className="mt-12" onClick={() => {
                setShowSuccess(false);
                navigate('/');
              }}>
                Close
              </button>
            </div>
          </SuccessModalWrapper>
        </Fade>
      </ModalCentered>
    )
  }

  return (
    <Wrapper>
      <h1 className="text-4xl font-bold font-display my-4">Got an idea to discuss?</h1>
      <p className="my-2 font-normal">Fill in what you like to get more from us. </p>

      <Formik
        initialValues={{
          email: ''
        }}
        validateOnBlur
        validationSchema={object({
          email: string().email('Should be a valid email').required('Email is required'),
        })}
        onSubmit={onSubmit}
      >
        <Form className="mb-32">
          <InputWrapper>
            <div>
              <label htmlFor="email">
                Your E-mail<span style={{ color: 'red' }}>*</span>
              </label>
            </div>
            <Field id="email" className="text" name="email" placeholder="your@email.com" />
            <ErrorMessage component="div" name="email" className="error" />
          </InputWrapper>
          <InputWrapper>
            <div>
              <label htmlFor="phone">
                Your Phone Number
              </label>
            </div>
            <Field id="phone" name="phone" className="text" placeholder="+1 23456789" />
          </InputWrapper>
          <InputWrapper>
            <div>
              <label htmlFor="idea">
                What is the main idea of the project?
              </label>
            </div>
            <Field id="idea" name="idea" className="text" placeholder="Ex: Food delivery app" />
          </InputWrapper>
          <InputWrapper>
            <div>
              <label htmlFor="primaryUsers">
                Who are the primary users of the software?
              </label>
            </div>
            <Field id="primaryUsers" name="primaryUsers" className="text" placeholder="Ex: Drivers and passengers" />
          </InputWrapper>
          <InputWrapper>
            <div>
              <label htmlFor="timeline">
                What is the desired timeline for completing the project?
              </label>
            </div>
            <Field id="timeline" name="timeline" className="text" placeholder="Ex: Roughly 3 months" />
          </InputWrapper>
          <InputWrapper>
            <div>
              <label>
                What are your expectations regarding software testing?
              </label>
            </div>
            <input className="text" placeholder="Ex: I want to test everything with QA, OR I am planning go to production later" />
          </InputWrapper>
          <InputWrapper>
            <div>
              <label>
                Are there any legal or compliance requirements?
              </label>
            </div>
            <input className="text" placeholder="Ex: Need PCI Compliance." />
          </InputWrapper>
          <InputWrapper>
            <div>
              <label className="">
                Your Project Expected Value (USD)
              </label>
            </div>
            <div className="row">
              <fieldset id="group2">
                <div>
                  <input type="radio" id="below10k" name="budget" value="below10k" checked />
                  <label for="below10k">Below 10K</label>
                </div>
                <div>
                  <input type="radio" id="below50k" name="budget" value="below50k" checked />
                  <label for="below50k">Below 50K</label>
                </div>
                <div>
                  <input type="radio" id="more50k" name="budget" value="more50k" checked />
                  <label for="more50k">More than 50K</label>
                </div>
                <div>
                  <input type="radio" id="undecide" name="budget" value="undecide" checked />
                  <label for="undecide">Not decided</label>
                </div>
              </fieldset>
            </div>
          </InputWrapper>
          <InputWrapper>
            <div>
              <label>
                Additional information about project such as features and technology
              </label>
            </div>
            <textarea placeholder="Anything you have to elaborate on requirements">
            </textarea>
          </InputWrapper>

          <button type="submit">
            Let's get started
          </button>
        </Form>
      </Formik>

    </Wrapper>
  )
}

export default Questionairs;