import styled, { keyframes } from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  min-height: 900px;
  background: #fff;
  display: block;
  margin-top: 10em;
  max-width: 768px;
  margin: 8em auto 0 auto;

  & > h1 {
    margin-bottom: 4px;
  }
  & > p {
    font-weight: 600;
    margin-top: 0px;
    margin-bottom: 3rem;
  }

  @media (max-width: 768px) {
    height: 100%;
  }

  & > form > button {
    border: none;
    font-size: 16px;
    padding: 0.76rem 2rem;
    margin: 2rem 0rem 0rem 0rem;
    background: #3D6BFB;
    color: #fff;
    border-radius: 8px;

    -webkit-transition: background-color .2s ease-out;
    -moz-transition: background-color .2s ease-out;
    -o-transition: background-color .2s ease-out;
    transition: background-color .2s ease-out;
  }

  & > form > button:hover {
    background: #DF7356;
    cursor: pointer;
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1.8rem;

  & > div > label {
    font-weight: 600;
  }
  & > .error {
    font-size: 14px;
    color: #FF5F5F;
  }
  & > input.text {
    margin-top: 0.5em;
    padding: 8px 16px;
    border: 2px solid ${({ theme }) => theme.palatte.text.default};
  }
  & > textarea {
    margin-top: 0.5em;
    padding: 8px 16px;
    height: 128px;
    border: 2px solid ${({ theme }) => theme.palatte.text.default};
  }
  
  & > .row {
    display: flex;
  }
  
  & > .row > fieldset {
    border: none;
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-weight: 600;
    margin-top: 16px;
  }
  
  & > .row > fieldset > div {
    display: flex;
    align-items: center;
  }
  
  & > .row > fieldset > div > input[type="radio"] {
  background-color: #fff;
  margin-right: 1rem;
  font: inherit;
  width: 1.15em;
  height: 1.15em;
  border: 1em solid ${({ theme }) => theme.palatte.text.default};
  border-radius: 50%;
}

`;


