import styled, {keyframes} from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  background: rgb(0,0,0);
  background: linear-gradient(0deg, rgba(0,0,0,0.86) 0%, rgba(0,0,0,0.61) 49%, rgba(255,255,255,0) 100%);
  
  & > div {
    max-width: 1000px;
    padding: 0px 8px;
  }

  & > div > h1 {
    font-size: 4em;
    font-weight: 400;
    width: 100%;
    color: #fff;
  }
  & > div > p {
    margin: 30px 0px 100px 0px;
    font-size: 1.125em;
    width: 100%;
    color: #fff;
  }
`;

