import styled from 'styled-components';

export const Wrapper = styled.div`
  margin: auto;
  position: relative;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  
  & > svg {
    width: calc(100% - 16px);
  }

  @media (max-width: 600px) {
    padding: 16px;
   
    & > svg {
      width: calc(100% - 16px);
    }
  } 
`;

export const Suggestions = styled.div`
  margin: 20px;

  & > div {
    display: flex;
    justify-content: space-between;
  }
  & > div > div {
    border: 1px solid ${({theme}) => theme.palatte.text.default};
    height: 60px;
    width: 60px;
  }
`;

export const Pointer = styled.div`
  position: absolute;
  top: 100px;

  & > div {
    width: 80px;
    margin-left: -160px;
    margin-top: -70px;
  }
  
  & > svg {
    margin-left: 60px;
    margin-left: -80px;
  }

  @media (max-width: 600px) {
    & > div {
      width: 80px;
      margin-right: 0px;
      margin-top: -70px;
    }
    & > svg {
      margin-left: -48px;
    }
  }  
`;

export const TitleSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;

  & > h3 {
    font-size: 1.5em;
    font-weight: 400;
  }
  & > p {
    font-size: 1em;
    font-weight: 400;
  }
  & > button {
    align-self: flex-end;
    border: 1px solid ${({theme}) => theme.palatte.text.default};
    color: #fff;
    width: 100%;
    font-size: 1em;
    height: 40px;
    background: ${({theme}) => theme.palatte.text.default};
  }
`;

export const PhoneSpan = styled.div`
  margin-left: 80px;
  height: 600px;
  overflow: hidden;
  width: 300px;
  border-radius: 24px;
  border: 1px solid ${({theme}) => theme.palatte.text.default};

  @media (max-width: 600px) {
    margin-right: auto;
    width: auto;
    height: 500px;
  }  
`;

export const BigDataSpan = styled.div`
  overflow: hidden;
  display: flex;
  justify-content: center;
  border-radius: 24px;
  width: calc(100% - 16px);
`;

export const WebSpan = styled.div`
  min-height: 400px;
  overflow: hidden;
  max-width: 585px;
  border-radius: 24px;
  width: 100%;
  border: 1px solid ${({theme}) => theme.palatte.text.default};
  margin: 8px;
    width: calc(100% - 16px);

  & > div {
    display: flex;
  }
  & > div > div {
    margin: 24px;
    width: 50%;
  }
  & > div > div:last-child {
    margin-left: 0px;
  }
  & > div > div > section {
    overflow: hidden;
    height: 210px;
  }

  @media (max-width: 600px) {
    width: calc(100% - 16px);
  }  
`;

export const ButtonSection = styled.div`
  & > button {
    border: 1px solid ${({theme}) => theme.palatte.text.default};
    color: ${({theme}) => theme.palatte.text.default};
    width: 100%;
    margin-bottom: 15px;
    font-size: 1em;
    height: 40px;
    background: #fff;
  }
`;

export const ServiceSection = styled.div`
  
  & > div {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
  } 
  & > div > div {

  } 
`;
